import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.css";
import { Checkout } from "./components/Checkout/Checkout";
import './App.css';
import { Elements } from '@stripe/react-stripe-js';
import * as Api from "./Api";
import { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import Interceptor from './modules/interceptor';

export const styled = {
  btnColor: "#000",
  btnTextColor: "#fff",
  textColor: "#000",
  bgColor: "#fbf3f1",
  fontFamily: `'Roboto', sans-serif`
}

Interceptor();

function App() {
  const searchParams = new URLSearchParams(window.location.search)
  const [activegateway, setactivegateway] = useState({})

  const getGateways = () => {
    let data = {}
    if (localStorage.getItem("cart_type") == "draft_order" && localStorage.getItem("draft_order_id")) {
      data = {
        type: localStorage.getItem("cart_type"),
        draft_order_id: localStorage.getItem("draft_order_id")
      }
    } else {
      data = {
        type: "cart",
      }
    }
    Api.GetApi(`/checkout/storeGateways`).then(resp => {
      console.log("res gateway: ", resp)
      if (resp.error === true) {
        Api.Alert(resp.response.data.error, "error")
      } else {
        if (resp.data.data) {
          let resData = resp.data.data.filter((x) => x.is_active && x.payment_gateway?.gateway_name == "Stripe")
          if (resData && resData.length > 0) {
            setactivegateway(resData[0])
          }
        }
      }
    })
  }

  useEffect(() => {
    if (Api.isLogIn().token) {
      getGateways()
    }
  }, [Api.isLogIn().token])
  console.log("activegateway: ", activegateway)

  return (
    <div className="App">
      <Router>
        <Routes>
          {/* <Route path="*" element={<NoPage />} exact /> */}
          <Route path="/" element={
            <Elements stripe={loadStripe(activegateway?.mode == "test" ? activegateway?.test_secret : activegateway?.production_secret)}>
              <Checkout />
            </Elements>
          } exact />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
