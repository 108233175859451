import axios from 'axios';
import { encrypt, decrypt } from './module';

const Interceptor = (headers) => {
  // For requests
  const axiosInstance = axios.create(headers);

  // Add request interceptor
  axiosInstance.interceptors.request.use(
    (config) => {
      // Do something before sending the request
      // For example, you can add headers or modify the request data
      config.headers["content-type"] = `application/json`;
      // console.log('Request config:', config);
      if (

        config.data &&
        process.env.REACT_APP_ENABLE_ENCRYPTION === "true"
      ) {

        config.data = { payload: encrypt(config.data) };
      }
      // console.log('Request Interceptor:', config);
      return config;
    },
    (error) => {
      // Do something with the request error
      // console.error('Request Interceptor Error:', error);
      return Promise.reject(error);
    }
  );

  // Add response interceptor
  axiosInstance.interceptors.response.use(
    (response) => {
      // Do something with the response data
      if (response.data.data) {
        try {
          response.data.data = decrypt(response.data.data);
          // console.log("after", config.data);
        } catch (err) { }
      }
      // console.log('Response Interceptor:', response);
      return response;
    },
    (error) => {
      // Do something with the response error
      if (error?.response?.data) {
        if (error.response.data.payload) {
          error.response.data = decrypt(error.response.data);
        }
      }
      // console.error('Response Interceptor Error:', error);
      return Promise.reject(error);
    }
  );
  return axiosInstance
}

export default Interceptor