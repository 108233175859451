import React from "react";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import Autocomplete from "react-google-autocomplete";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import states from "../states";
import "./Shipping.css";

const Shipping = ({ selectCountry, handleChange, handleSelectChange, showError, handleNumberChange, addressType, address }) => {
    let prefix = ""
    function nameFn(val) {
        if (addressType == "shipping_address") {
            prefix = val
        } else {
            prefix = `bill_${val}`
        }
    }
    return (
        <div className="">
            {/* <div className="form_outer">
                <label className="checkout_checkbox">
                    <input type="checkbox" name="subscribe" />
                    <span></span>
                    <div className="checkout_checkbox_text">Subscribe to our newsletter</div>
                </label>
            </div> */}
            <div className="">
                <div className="">
                    <div className="checkout_row">
                        <div className="checkout_col col_6">
                            <div className="form_control">
                                <label className="form_label">First name*</label>
                                <input
                                    type="text"
                                    className={`checkout_input ${showError && (!address[addressType]?.first_name || address[addressType]?.first_name == "") ? "error" : ""}`}
                                    onChange={(e) => handleChange(e, addressType, 30)}
                                    name="first_name"
                                    value={address[addressType]?.first_name}
                                // placeholder="First name*"
                                />
                            </div>
                        </div>
                        <div className="checkout_col col_6">
                            <div className="form_control">
                                <label className="form_label">Last name</label>
                                <input
                                    type="text"
                                    className="checkout_input"
                                    onChange={(e) => handleChange(e, addressType, 30)}
                                    name="last_name"
                                    value={address[addressType]?.last_name}
                                // placeholder="Last name"
                                />
                            </div>
                        </div>
                        <div className="checkout_col col_6">
                            <div className="form_control">
                                <label className="form_label">Company (optional)</label>
                                <input type="text" className="checkout_input" onChange={(e) => handleChange(e, addressType, 30)} name="company" value={address[addressType]?.company}
                                // placeholder="Company (optional)"
                                />
                            </div>
                        </div>
                        <div className="checkout_col col_6">
                            <div className="form_control">
                                <label className="form_label">Contact no.*</label>
                                <PhoneInput
                                    enableSearch={true}
                                    disableAreaCodes={false}
                                    country="in"
                                    value={address[addressType]?.phone}
                                    inputExtrastate={{ name: 'mobile', required: true }}
                                    inputProps={{ maxLength: '17', className: `checkout_input ${showError && (!address[addressType]?.phone || address[addressType]?.phone == "" || address[addressType]?.phone == "+91 " || (address[addressType]?.phone.includes("+91 ") && (address[addressType]?.phone.split("+91 ")[1].length > 0 && address[addressType]?.phone.split("+91 ")[1].length < 10))) ? "error" : ""}` }}

                                    onChange={(e, x) => handleNumberChange(e, x, addressType)}
                                    isValid={(value, data) => {
                                        if (
                                            value
                                                .replace(/[^0-9]+/g, "")
                                                .slice(data && (data.dialCode.length)).length < 10 &&
                                            value
                                                .replace(/[^0-9]+/g, "")
                                                .slice(data && (data.dialCode.length)).length > 0
                                        ) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    }}
                                    placeholder="Contact no."
                                    countryCodeEditable={false}
                                    defaultMask=".. ... ...."
                                />
                            </div>
                        </div>
                        <div className="checkout_col col_6">
                            <div className="form_control">
                                <label className="form_label">Address line 1*</label>
                                <input type="text" className={`checkout_input ${showError && (!address[addressType]?.address1 || address[addressType]?.address1 == "") ? "error" : ""}`} onChange={(e) => handleChange(e, addressType, 80)} name="address1" value={address[addressType]?.address1 || ""} />
                            </div>
                        </div>
                        <div className="checkout_col col_6">
                            <div className="form_control">
                                <label className="form_label">Address line 2</label>
                                <input type="text" className="checkout_input" onChange={(e) => handleChange(e, addressType, 80)} name="address2" value={address[addressType]?.address2 || ""} />
                            </div>
                        </div>
                        <div className="checkout_col col_6">
                            <div className="form_control">
                                <label className="form_label">City*</label>
                                <input type="text" className={`checkout_input ${showError && (!address[addressType]?.city || address[addressType]?.city == "") ? "error" : ""}`} onChange={(e) => handleChange(e, addressType, 30)} name="city" value={address[addressType]?.city || ""} />
                            </div>
                        </div>
                        <div className="checkout_col col_6">
                            <div className="form_control custom_select">
                                <label className="form_label">State</label>
                                {/* <input type="text" className="checkout_input" onChange={(e) => handleChange(e, addressType, 30)} name="province" placeholder="" value={address[addressType]?.province} /> */}
                                <select className="checkout_input" onChange={(e) => handleSelectChange(e, addressType)} name="province" value={address[addressType]?.province || "State"}>
                                    <option value={"State"} disabled>State</option>
                                    {states.map((opt) => {
                                        return (
                                            <option key={opt?.code} value={opt?.name}>{opt?.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                        {/* {addressType == "shipping_address" ?
                                <div className="form_control">
                                    <label className="form_label">Country</label>
                                    <div className="checkout_input">{address[addressType]?.country || "India"}</div>
                                </div>
                                :
                                <div className="form_control w-100">
                                    <label className="form_label">Country</label>
                                    <CountryDropdown
                                        defaultOptionLabel="Country"
                                        value={address[addressType]?.country}
                                        name="country"
                                        className={`address[checkout_input] w-100`}
                                        onChange={(val) => selectCountry(val, addressType)}
                                    />
                                </div>
                            } */}
                        <div className="checkout_col col_6">
                            <div className="form_control">
                                <label className="form_label">Zip code*</label>
                                <input type="number" className={`checkout_input ${showError && (!address[addressType]?.zip_code || address[addressType]?.zip_code == "") ? "error" : ""}`} onChange={(e) => handleChange(e, addressType, 6)} name="zip_code"  value={address[addressType]?.zip_code || ""} />
                            </div>
                        </div>
                        <div className="checkout_col col_6">
                            <div className="form_control">
                                <label className="form_label">Country</label>
                                <div className="checkout_input">{address[addressType]?.country || "India"}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { Shipping }