import axios from "axios";
import Interceptor from "./modules/interceptor";

export let appURL = ""
if (process.env.REACT_APP_MODE === 'prod') {
    console.log = function () { };
    appURL = "shopyxa.com"
} else if (process.env.REACT_APP_MODE === 'dev') {
    appURL = "forgetaday.com"
} else {
    appURL = "shopyxo.com"
}
export const base_URL = `https://paycheckout.${appURL}/v1`
export const Api = axios.create({
    baseURL: base_URL
});

export const CURRENCY = '₹'
export const CURRENCY_CODE = 'INR'

export const isLogIn = () => {
    if (localStorage.getItem('cart_token')) {
        return { isToken: true, token: localStorage.getItem('cart_token') };
    } else {
        return { isToken: false, token: '' };
    }
};

export const toFixed = (val) => {
    return (val).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
}

export const Alert = (message, type) => {
    let div = document.createElement('div');
    div.classList.add("custom_alert")
    div.classList.add(type)
    div.innerHTML += `<p>${message}</p>`
    document.body.append(div)
    setTimeout(() => {
        document.body.querySelector(".custom_alert").remove()
    }, 3000);
}
console.log("isLogIn(): ", isLogIn())
const headers = () => {
    if (isLogIn().token) {
        return {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Authorization": isLogIn().token,
            "Content-Security-Policy": "frame-ancestors https://www.payumoney.com https://www.goibibobusiness.com https://www.premiermiles.co.in https://goibibo.com https://secure.skype.com https://www.facebook.com https://api.payu.in"
            
        }
    } else {
        return {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Content-Security-Policy": "frame-ancestors https://www.payumoney.com https://www.goibibobusiness.com https://www.premiermiles.co.in https://goibibo.com https://secure.skype.com https://www.facebook.com https://api.payu.in"
        }
    }
}

function returnAxiosInstance(customHeaders = headers()) {
    return Interceptor({
        baseURL: base_URL,
        headers: customHeaders,
    });
}

//Handle API response
function handleResponse(response) {
    if (response.status === 200 || response.status === 201) {
        return response
    }
    if (response.status === 500 || response.status === 404) {
        return { response: { data: { error: response.data.error } }, error: true }
    }
    else {
        return { response: { data: { error: response.data.error.message } }, error: true }
    }
}

let errorData = (err) => {
    return { error: err.response && err.response.data ? (typeof err.response.data.error == "string" ? err.response.data.error : err.response.data.error.message) : "Something went wrong" }
}

//API methods Admin
export const GetApi = async (url, customHeaders = headers()) => {
    const axios = returnAxiosInstance(customHeaders);
    return axios.get(url).then(handleResponse).catch((err) => {
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}
export const PostApi = async (url, body, customHeaders = headers()) => {
    const axios = returnAxiosInstance(customHeaders);
    return axios.post(url, body).then(handleResponse).catch((err) => {
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}
export const PutApi = async (url, body, customHeaders = headers()) => {
    const axios = returnAxiosInstance(customHeaders);
    return axios.put(url, body).then(handleResponse).catch((err) => {
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}
export const DeleteApi = async (url, customHeaders = headers()) => {
    const axios = returnAxiosInstance(customHeaders);
    return axios.delete(url).then(handleResponse).catch((err) => {
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}
