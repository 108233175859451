//https://www.npmjs.com/package/@stripe/react-stripe-js
import React from 'react';
import {
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import cvv from "../../../assets/images/cards/cvv.png";
import * as Api from "../../../Api";
import { logEvent, Result, ErrorResult } from '../utills';


const ELEMENT_OPTIONS = {
    style: {
        base: {
            fontSize: '14px',
            color: '#535353',
            letterSpacing: '0.025em',
            '::placeholder': {
                color: '#535353',
            },
        },
        invalid: {
            color: '#ff0000',
        },
    },
    hideIcon: true,
};


const CheckoutForm = (props) => {
    const elements = useElements();
    const stripe = useStripe();
    // const [errorMessage, setErrorMessage] = useState(null);
    // const [paymentMethod, setPaymentMethod] = useState(null);


    const stripePayment = (data) => {

    }
    console.log("props.data: ", props.data)
    // const handleSubmit = async (event) => {
    //     event.preventDefault();

    //     if (!stripe || !elements) {
    //         // Stripe.js has not loaded yet. Make sure to disable
    //         // form submission until Stripe.js has loaded.
    //         return;
    //     }

    //     const card = elements.getElement(CardNumberElement);

    //     if (card == null) {
    //         return;
    //     }

    //     const payload = await stripe.createPaymentMethod({
    //         type: 'card',
    //         card,
    //     });


    //     if (payload.error) {
    //         console.log('[error]', payload.error);
    //         // setErrorMessage(payload.error.message);
    //         // setPaymentMethod(null);
    //     } else {
    //         stripe.createToken(card).then(function (result) {
    //             console.log("result: ", result)
    //             // stripe.createSource({
    //             //     type: 'ideal',
    //             //     amount: 1099,
    //             //     currency: 'eur',
    //             //     owner: {
    //             //         name: 'Jenny Rosen',
    //             //     },
    //             //     redirect: {
    //             //         return_url: 'https://shop.example.com/crtA6B28E1',
    //             //     },
    //             // })
    //             //     .then(function (result2) {
    //             //         console.log("result2: ", result2)
    //             //     });
    //             let body = {
    //                 ...props.data,
    //                 card_token: result?.token?.id,
    //                 paymentMethod_id: payload.paymentMethod?.id
    //             }
    //             Api.PostApi("/checkout/stripe", body).then(resp => {
    //                 console.log("res stripe: ", resp)
    //                 if (resp.error === true) {
    //                     Api.Alert(resp.response.data.error, "error")
    //                 } else {


    //                     //    window.open(resp?.data.data?.next_action?.use_stripe_sdk?.stripe_js, "_target")

    //                     // stripe.handleCardAction(resp?.data.data?.client_secret).then(function (result2) {
    //                     //     if (result2.error) {
    //                     //         console.log("action error: ", result2.error);
    //                     //         /* Handle error */
    //                     //     } else {
    //                     //         console.log("action success: ", result2);
    //                     //     }
    //                     // })
    //                     stripe.confirmCardPayment(resp?.data.data?.client_secret, {
    //                         payment_method: {
    //                             card: card,
    //                             billing_details: {
    //                                 name: 'Jenny Rosen',
    //                             },
    //                         },
    //                     }).then(function (result2) {
    //                         if (result2.error) {
    //                             console.log("action error: ", result2.error);
    //                             /* Handle error */
    //                         } else {
    //                             console.log("action success: ", result2);
    //                             let body2 = {
    //                                 ...props.data,
    //                                 payment_intent: result2?.paymentIntent?.id
    //                             }
    //                             Api.PostApi("/checkout/stripe/capture", body2).then(res => {
    //                                 console.log("res stripe capture: ", res)
    //                                 if (res.error === true) {
    //                                     Api.Alert(res.response.data.error, "error")
    //                                 } else {

    //                                 }
    //                             })
    //                         }
    //                     })
    //                 }
    //             })
    //         })
    //         console.log('[PaymentMethod]', payload.paymentMethod);
    //         // setPaymentMethod(payload.paymentMethod);
    //         // setErrorMessage(null);
    //     }
    // };

    return (
        <form className="stripe_form">
            <div className="checkout_row">
                <div className="checkout_col">
                    <div className="form_control">
                        <CardNumberElement
                            id="cardNumber"
                            onBlur={logEvent('blur')}
                            onChange={props.handleCardChange}
                            onFocus={logEvent('focus')}
                            onReady={logEvent('ready')}
                            options={ELEMENT_OPTIONS, { placeholder: "Card number" }}
                            className="checkout_input stripe_input"
                        />
                    </div>
                </div>
                <div className="checkout_col col_6 col_sm_6">
                    <div className="form_control">
                        <CardExpiryElement
                            id="expiry"
                            onBlur={logEvent('blur')}
                            onChange={props.handleCardChange}
                            onFocus={logEvent('focus')}
                            onReady={logEvent('ready')}
                            options={ELEMENT_OPTIONS, { placeholder: "MM/YY" }}
                            className="checkout_input stripe_input"
                        />
                    </div>
                </div>
                <div className="checkout_col col_6 col_sm_6">
                    <div className="form_control">
                        <CardCvcElement
                            id="cvc"
                            onBlur={logEvent('blur')}
                            onChange={props.handleCardChange}
                            onFocus={logEvent('focus')}
                            onReady={logEvent('ready')}
                            options={ELEMENT_OPTIONS, { placeholder: "CVV" }}
                            className="checkout_input stripe_input"
                        />
                        <span className='cvv_tooltip'>i</span>
                        <div className='cvv_tooltip_outer'>
                            {/* <p>3-digit security code usually found on the back of your card.</p> */}
                            <img src={cvv} alt="CVV" />
                        </div>
                    </div>
                </div>
            </div>
            {/* {errorMessage && <ErrorResult>{errorMessage}</ErrorResult>}
            {paymentMethod && <Result>Got PaymentMethod: {paymentMethod.id}</Result>} */}
            {/* <button type="submit" disabled={!stripe}>
                Pay
            </button> */}
        </form>
    );
};

export { CheckoutForm }