import crypto from "crypto-browserify";

let algorithm = process.env.REACT_APP_ENCRYPTION_ALGORITHM;
let ivLength = 12;
let tagLength = 16;
let format = process.env.REACT_APP_ENCRYPTION_FORMAT;
let key = process.env.REACT_APP_ENCRYPTION_KEY;
function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export const encrypt = (data) => {
    const iv = crypto.randomBytes(ivLength);
    const cipher = crypto.createCipheriv(algorithm, key, iv);
    let cipherText = cipher.update(JSON.stringify(data), "utf-8");
    cipherText = Buffer.concat([cipherText, cipher.final()]);
    const tag = cipher.getAuthTag();
    const encryptedData = Buffer.concat([iv, cipherText, tag]);
    // console.log("encryptedData.toString(format): ", encryptedData.toString(format))
    return encryptedData.toString(format);
};
export const decrypt = (data) => {
    const encryptedBuffer = Buffer.from(data, format);
    const iv = encryptedBuffer.slice(0, ivLength);
    const cipherText = encryptedBuffer.slice(ivLength, -tagLength);
    const tag = encryptedBuffer.slice(-tagLength);
    const decipher = crypto.createDecipheriv(algorithm, key, iv);
    decipher.setAuthTag(tag);
    let data2 = decipher.update(cipherText, "base64", "utf8");
    data2 += decipher.final("utf8");
    // console.log("data2: ", data2)
    return isJsonString(data2) ? JSON.parse(data2) : data2;
};