import React, { useState } from "react";
import card from "../../../assets/images/cards/card-icon-blank.png";
import visa from "../../../assets/images/cards/visa.svg";
import amex from "../../../assets/images/cards/american-express.svg";
import mastercard from "../../../assets/images/cards/mastercard.svg";
import jcb from "../../../assets/images/cards/jcb.svg";
import diners from "../../../assets/images/cards/diners.svg";
import discover from "../../../assets/images/cards/discover.svg";
import razorpay from "../../../assets/images/cards/razorpay.svg";
import payUmoney from "../../../assets/images/cards/payumoney.png";
import allcard from "../../../assets/images/cards/all_cards.png";
import cashfree from "../../../assets/images/cards/cashfree.png";
// import paypal from "../../../assets/images/cards/paypal.svg";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { CheckoutForm } from './CheckoutForm';
import "./Payment.css";
import _ from "underscore";



function Payment(props) {
    console.log("props.activegateway: ", props.activegateway)
    return (
        <div className="checkout_payment_form">
            <div className="form_outer">
                <label>
                    Payment information
                    <p><small>All transactions are secure and encrypted</small></p>
                </label>
                {props.gateways.length > 0 ?
                    <>
                        {props.gateways.filter((x) => x?.payment_gateway?.gateway_name !== "PayTm").map((data, i) => {
                            return (
                                <div className="checkout_radio_outer" key={i} id={`gateway_${data?.id}`}>
                                    <label className="checkout_checkbox checkout_radio">
                                        <input type="radio" name="payment" id={data?.id} value={data?.id} checked={props.activegateway.id === data?.id ? "checked" : ""} onChange={(e) => props.handlePaymentChange(e, data)} />
                                        <span></span>
                                        <div className="checkout_checkbox_text">
                                            {data?.payment_gateway?.gateway_name == "Stripe" ?
                                                <img src={allcard} height="18px" alt="Stripe" /> :
                                                data?.payment_gateway?.gateway_name == "RazorPay" ?
                                                    <img src={razorpay} height="18px" alt="RazorPay" /> :
                                                data?.payment_gateway?.gateway_name == "Cashfree" ?
                                                    <img src={cashfree} height="18px" alt="Cashfree" /> :
                                                    data?.payment_gateway?.gateway_name == "PayUMoney" ?
                                                        <img src={payUmoney} height="20px" alt="PayUMoney" /> :
                                                        data?.payment_gateway?.gateway_name == "COD" ?
                                                            "Cash on delivery" :
                                                            data?.payment_gateway?.gateway_name
                                            }
                                            <span className="checkout_cards">
                                                {data?.payment_gateway?.gateway_name == "Stripe" ?
                                                    <>
                                                        {props.cardData?.brand == "mastercard" ?
                                                            <img src={mastercard} alt="Mastercard" />
                                                            :
                                                            props.cardData?.brand == "visa" ?
                                                                <img src={visa} alt="Visa" />
                                                                :
                                                                props.cardData?.brand == "amex" ?
                                                                    <img src={amex} alt="AMEX" />
                                                                    :
                                                                    props.cardData?.brand == "diners" ?
                                                                        <img src={diners} alt="Diners" />
                                                                        :
                                                                        props.cardData?.brand == "jcb" ?
                                                                            <img src={jcb} alt="JCB" />
                                                                            :
                                                                            props.cardData?.brand == "discover" ?
                                                                                <img src={discover} alt="Discover" />
                                                                                :
                                                                                <img src={card} alt="Stripe" />
                                                        }
                                                    </>
                                                    :
                                                    null
                                                    // data?.payment_gateway?.url ?
                                                    //     <img src={data?.payment_gateway?.url} alt="" /> : null
                                                }
                                                {/* <img src={visa} alt="Visa" />
                                        <img src={amex} alt="Amex" />
                                        <img src={mastercard} alt="Mastercard" />
                                        <img src={jcb} alt="jcb" />
                                        <small>and more...</small> */}
                                            </span>
                                        </div>
                                    </label>
                                    {props.activegateway.id === data?.id && data?.payment_gateway?.gateway_name == "Stripe" &&
                                        <div className="payment_gateway_form">
                                            {/* <Elements stripe={loadStripe(props.activegateway?.mode == "test" ? props.activegateway?.test_secret : props.activegateway?.production_secret)}> */}
                                            <CheckoutForm data={props.stripeData} handleCardChange={props.handleCardChange} handleCVCChange={props.handleCVCChange} handleExpChange={props.handleExpChange} />
                                            {/* </Elements> */}
                                        </div>
                                    }
                                </div>
                            )
                        })}
                    </>
                    :
                    <h6 style={{ paddingTop: "10px" }}>No payment gateway configured</h6>
                }
                {props.showError && _.isEmpty(props.activegateway) &&
                    <p style={{ color: "red" }}>Please select payment method</p>
                }

            </div>
        </div>
    )
}

export { Payment }